<template>
  <a-layout class="custom-layout">
    <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
    <div class="batch-content auto">
      <a-layout class="content">
        <a-layout-sider class="custom-sider">
          <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
          <div class="batch-menu">
            <ul>
              <li :class="menuIndex === 1 && 'cur'" @click="changeMenu(1)">导入成功用户</li>
              <li :class="menuIndex === 2 && 'cur'" @click="changeMenu(2)">导入失败用户</li>
            </ul>
          </div>
        </a-layout-sider>
        <a-layout-content class="custom-content auto" style="position:relative;">
          <a-spin :spinning="loading">
            <div class="content-title auto">
              <div class="coustom-title fl" v-if="menuIndex === 1">导入成功记录（{{count}}条）</div>
              <div class="coustom-title fl" v-else>导入失败记录（{{count}}条）</div>
            </div>
            <div class="content-bar auto">
              <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
                <div slot="custom">
                  <a-button size="small" @click="exportList" v-show="count > 0">导出当前列表</a-button>
                </div>
              </custom-page>
            </div>
            <div class="content-table auto">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
                <div slot="status" slot-scope="text, record">
                  <span v-if="text === 1">
                    <a-badge status="success" text="处理成功" />
                  </span>
                  <span v-if="text === -1">
                    <a-badge status="warning" text="处理失败" />
                  </span>
                  <span v-if="text === 0">
                    <a-badge status="processing" :text="处理中" />
                  </span>
                </div>
                <div slot="action" slot-scope="text, record">
                  <a-button type="primary" size="small" @click="downLoadHandle(record)" v-show="record.status === 1" class="m-r-5">下载</a-button>
                  <a-popconfirm
                    title="确定要删除吗?"
                    ok-text="确定"
                    cancel-text="取消"
                    placement="topRight"
                    @confirm="deleteHandle(record)"
                  >
                    <a-button type="danger" size="small">删除</a-button>
                  </a-popconfirm>
                </div>
              </a-table>
            </div>
            <div class="content-bar auto">
              <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
                <div slot="custom">
                  <a-button size="small" @click="exportList" v-show="count > 0">导出当前列表</a-button>
                </div>
              </custom-page>
            </div>
          </a-spin>
        </a-layout-content>
      </a-layout>
    </div>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      id: '',
      isResult: true,
      loading: false,
      menuIndex: 1,
      tablecolumns: [
        {
          title: '账号',
          dataIndex: 'a_username'
        },
        {
          title: '学工号',
          dataIndex: 'u_schoolid'
        },
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      tabledata: [],
      count: 0,
      filter: {
        current_page: 1,
        page_size: 10
      },
      type: 'normal'
    }
  },
  methods: {
    exportList () {
      window.open(this.config.api + 'backup/import/record/detail/user/download/' + this.type + '/' + this.id, '_blank')
    },
    async deleteHandle (row) {
      this.loading = true
      let res = await System.deleteExport(row.id)
      this.loading = false
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getBackupList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    downLoadHandle (row) {
      window.open(this.config.api + 'backup/export/record/detail/' + row.id, '_blank')
    },
    changeMenu (index) {
      this.filter = {
        current_page: 1,
        page_size: 10
      }
      this.tabledata = []
      this.count = 0
      this.menuIndex = index
      if (index === 1) {
        this.type = 'normal'
      } else {
        this.type = 'error'
      }
      this.getBackupList()
    },
    getPageMes (obj) {
      this.filter = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.getBackupList()
    },
    back () {
      this.$router.go(-1)
    },
    async getBackupList () {
      this.loading = true
      let res = await System.getImportUserList(this.type, this.id, this.filter)
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getBackupList()
  }
};
</script>

<style scoped>
.batch-content {
  width: 100%;
  height: 100%;
}
.batch-wrap {
  width: 326px;
  margin: 0 auto;
}
.batch-title {
  text-align: center;
  line-height: 62px;
}
.batch-top {
  width: 324px;
  border: 1px solid #91D5FF;
  background: #E6F7FF;
  text-align: center;
  padding: 15px 0;
}
.batch-upload {
  margin-top: 15px;
}
.excel-icon i {
  color: rgba(0,0,0,0.10)!important;
  margin-top: 15px;
}
.excel-text {
  color: rgba(0,0,0,0.25)!important;
  font-size: 14px!important;
  margin-bottom: 10px!important;
}
.excel-btn {
  padding-bottom: 20px;
}

.content-bar {
  padding: 8px 0;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
}
.batch-menu ul li.cur {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  background: #E6F7FF;
}
</style>